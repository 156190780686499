<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        The graph shown below is the hypothetical heating curve showing the temperature of
        <span class="text-bold text-danger">1 mol</span>
        of a substance versus the amount of heat energy added to said substance.
      </p>

      <p class="mb-3 text-center">
        <v-img class="d-inline-block" :src="imageName" width="450px" />
      </p>

      <p class="mb-0">
        a) Which line segment(s) correspond to the
        <stemble-latex :content="`$\\textbf{${phaseA}}$`" />
        phase of the substance?
      </p>

      <v-simple-table class="mb-4 pl-7">
        <tbody>
          <tr>
            <td v-for="segmentOption in segmentOptions" :key="segmentOption">
              <v-checkbox v-model="inputs.input1" :value="segmentOption" :label="segmentOption">
                <template #label>
                  <stemble-latex :content="`$\\text{${segmentOption}}$`" />
                </template>
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-0">b) Which line segment(s) correspond to phase changes?</p>

      <v-simple-table class="mb-4 pl-7">
        <tbody>
          <tr>
            <td
              v-for="segmentOption in segmentOptions"
              :key="segmentOption"
              style="text-align: center"
            >
              <v-checkbox v-model="inputs.input2" :value="segmentOption" :label="segmentOption">
                <template #label>
                  <stemble-latex :content="`$\\text{${segmentOption}}$`" />
                </template>
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        c) At what temperature does the substance
        <stemble-latex :content="`$\\textbf{${phaseChangeC}}$`" />
        ?
      </p>
      <calculation-input
        v-model="inputs.input3"
        class="mb-6"
        prepend-text="$\text{T:}$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) Using the figure, approximate the molar heat capacity of the substance in the liquid
        phase in
        <stemble-latex content="$\text{kJ mol}^{-1}\,^\circ\text{C}^{-1}$" />
        . Express your answer to <b>2 decimal places</b>.
      </p>
      <calculation-input
        v-model="inputs.input4"
        class="mb-6"
        prepend-text="$\text{C:}$"
        append-text="$\text{kJ mol}^{-1}\,^{\circ}\text{C}^{-1}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        e) Using the graph, approximate the molar enthalpy of fusion of the substance.
      </p>
      <calculation-input
        v-model="inputs.input5"
        class="mb-4"
        prepend-text="$\Delta\text{H}_{\text{fus}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question252',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
        input3: null,
        input4: null,
        input5: null,
      },
      options: [
        {expression: '$\\text{A}$', value: 'A'},
        {expression: '$\\text{B}$', value: 'B'},
        {expression: '$\\text{C}$', value: 'C'},
        {expression: '$\\text{D}$', value: 'D'},
        {expression: '$\\text{E}$', value: 'E'},
      ],
    };
  },

  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content.value;
    },
    segmentOptions() {
      return ['A', 'B', 'C', 'D', 'E'];
    },
    imageName() {
      return `/img/assignments/heatingProfile${this.versionNumber}.png`;
    },
    versionData() {
      if (this.versionNumber === 1 || this.versionNumber === 2) {
        return {
          phaseA: 'solid',
          phaseChangeC: 'melt',
        };
      } else if (this.versionNumber === 3 || this.versionNumber === 4) {
        return {
          phaseA: 'liquid',
          phaseChangeC: 'freeze',
        };
      } else if (this.versionNumber === 5 || this.versionNumber === 6) {
        return {
          phaseA: 'gas',
          phaseChangeC: 'boil',
        };
      } else {
        return {
          phaseA: 'ERROR',
          phaseChangeC: 'ERROR',
        };
      }
    },
    phaseA() {
      return this.versionData.phaseA;
    },
    phaseChangeC() {
      return this.versionData.phaseChangeC;
    },
  },
};
</script>
